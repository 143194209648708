import React, { useEffect, useState } from "react";
import { addToCartHandler } from "../../../actions/product/action";
import CarouselComponent from "../../common/Carousel/Carousel";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Toaster from "../../common/Toaster";
import "./style.scss";
import { connect } from "react-redux";
import { Link, navigate } from "gatsby";
import Loader from "../../common/Loader";

const PantoneCard = ({addToCartHandler,state}) => {
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [checkMark, setCheckMark] = useState(true);
  const [loading, setLoading] = useState(false);

  let casrouselImages = (
    <CarouselComponent
      images={[
        { _id: 1, image: "/images/common/pantones/1.webp" },
        { _id: 2, image: "/images/common/pantones/2.webp" },
        { _id: 3, image: "/images/common/pantones/3.webp" },
        { _id: 4, image: "/images/common/pantones/4.webp" },
      ]}
    />
  );

  const productImages = (
    <>
      <img src="/images/common/pantones/1.webp" key="1" />
      <img src="/images/common/pantones/2.webp" key="2" />
      <img src="/images/common/pantones/3.webp" key="3" />
      <img src="/images/common/pantones/4.webp" key="4" />
    </>
  );

  const checkMarkHandler = () => {
    setCheckMark((prevState) => {
      return !prevState;
    });
  };

  const addToProduction = async () => {
    if (!checkMark) {
      setToastrMsg("Please agree to the TnC's");
      doRefresh((prev) => prev + 1);
      return;
    }

    setLoading(true);
    let obj = {
      colors: [],
      totalPrice: 295,
      totalQuantity: 1,
      productUniqueId: 8783198911,
      sample: false,
      others:"BOOK",   
      productId: "6312dbcb06e54023cfe99342",//"prod"
      userId: localStorage.getItem("userId"),
      addons: [],
    };
    if (!obj.userId || obj.userId === null) {

      let cartItems = JSON.parse(localStorage.getItem("cartItems"));
      let items =
        cartItems && cartItems != null && cartItems.length > 0
          ? [...cartItems]
          : [];

          

      let newOrderObject = { ...obj, product: {
        internalId:"BOOK",
        images:[{image:"/images/common/pantones/1.webp"}]
      } };
      let newItems = [...items, newOrderObject];

      localStorage.setItem("cartItems", JSON.stringify(newItems));
      navigate("/products/cart");

    }
    else{
       // console.log(obj);
    var addToCart = await addToCartHandler(obj);
    if (addToCart && !addToCart.error) {
      navigate("/products/cart");
    } else {
      setLoading(false);
      setToastrMsg("Unable to add to Cart!!");
      doRefresh((prev) => prev + 1);
    }
    }
   


  };

  return (
    <>
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <Loader isLoading={loading} />
      <Header headerBg="#fffbfb" />
      <div className="pantone-main">

      
      <div className="pantones products-image">
        <div className="col-12 col-md-6 hide-desktop">
          <div className="mobile-images">{casrouselImages}</div>
        </div>
      </div>

      <div className="pantones products">
        <div className="row">
          <div className="col-12 col-md-7 col-images">
            <div className="hide-mobile">
              <div className="desktop-images">{productImages}</div>
            </div>
          </div>
          <div className="col-12 col-md-5 col-info">
            <div className="product-title pantone-title">
              <div className="pantone-left">
              HARDCOPY COLOR BOOK
              </div>
              <div>$295</div>
            </div>
            <div className="book-info">
            For designers who prefers hard copy. It’s the perfect tool to get inspired and create an ideal collection.
              <ul>
                <li>
                Illustrates 1000+ Colors
                </li>
                <li>
                Colors match our online color library.{" "}
                </li>
                <li>
                Two compact, fan decks for easy reference and viewing on the go
                </li>
                <li>Eco-friendly coating formulation</li>
              </ul>
            </div>
            <div className="order">
              <button onClick={addToProduction}>ORDER</button>
              <div className="order-checkbox">
                <div className="checkmark" onClick={checkMarkHandler}>
                  {checkMark && <img src="/images/common/tick.svg" />}
                </div>
                Ships within 5-7 business days.
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  state: state.products,
});

export default connect(mapStateToProps, {
  
  addToCartHandler
})(PantoneCard);
