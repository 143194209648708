import React from 'react'
import PantoneCard from '../../components/cards/pantoneCard'


const Pantonebook = () => {
  return (
    <div className='pc-hidden'>
    <PantoneCard />
  
    </div>
    
    
  )
}

export default Pantonebook